import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

// import StateContext from 'context/state-context';

import Header from 'components/Layout/Header';
import Login from 'pages/Login';
import SupplementGuide from 'pages/SupplementGuide';
import MealPlan from 'pages/MealPlan';
import ThankYou from 'pages/ThankYou';
import PlanSummary from 'pages/PlanSummary';

//refactor later
// import StateContext from 'context/state-context';
// import UserContext from 'context/user-context';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const App = () => {
  // const stateCtx = useContext(StateContext);

  return (
    <>
      <Header />
      <Container component='main' maxWidth='lg' sx={{ m: '3rem auto' }}>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          minHeight='70vh'
        >
          <Routes>
            <Route index element={<Login />}></Route>
            <Route
              path='supplement-guide'
              element={<SupplementGuide />}
            ></Route>
            <Route path='meal-plan' element={<MealPlan />}></Route>
            <Route path='thank-you' element={<ThankYou />}></Route>
            <Route path='plan-summary' element={<PlanSummary />}></Route>
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </Grid>
      </Container>
    </>
  );
};

export default App;
