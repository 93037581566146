import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import UserContext from 'context/user-context';

import styled from '@emotion/styled';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  FormControl,
  OutlinedInput,
} from '@mui/material';

import UIButton from 'components/UI/UIButton';
import SupplementsCard from 'components/SupplementsCard';

// object to hold all supplements
const supplementItems = {
  postworkout_mealreplacement: {
    cardTitle: 'Post-Workout / Meal Replacement Essentials',
    stackName: '',
    supplementOptions: [
      {
        label: 'Protein',
        name: 'protein',
      },
      {
        label: 'Mass Gainer',
        name: 'mass_gainer',
      },
      {
        label: 'Complex Carbohydrates',
        name: 'complex_carbohydrates',
      },
      {
        label: 'Meal Replacement',
        name: 'meal_replacement',
      },
      {
        label: 'Glutamine',
        name: 'glutamine',
      },
      {
        label: 'Other',
        name: 'other',
      },
    ],
  },
  health_wellness: {
    cardTitle: 'Health & Wellness Essentials',
    stackName: '',
    supplementOptions: [
      {
        label: 'Multi-Vitamin',
        name: 'multivitamin',
      },
      {
        label: 'Joint Support',
        name: 'joint_support',
      },
      {
        label: 'Fruits & Greens',
        name: 'fruits_greens',
      },
      {
        label: 'Liver / Prostate Support',
        name: 'liver_prostate_support',
      },
      {
        label: 'Omega 3s',
        name: 'omega_3s',
      },
      {
        label: 'Probiotics',
        name: 'probiotics',
      },
      {
        label: 'CBD',
        name: 'cbd',
      },
      {
        label: 'Other',
        name: 'other',
      },
    ],
  },
  fat_loss_weight_management: {
    cardTitle: 'Fat Loss / Weight Management',
    stackName: '',
    supplementOptions: [
      {
        label: 'Thermogenic Fat Burner',
        name: 'thermogenic_fat_burner',
      },
      {
        label: 'GDA',
        name: 'gda',
      },
      {
        label: 'Fat Burning Sleep Aid',
        name: 'fat_burning_sleep_aid',
      },
      {
        label: 'Detox',
        name: 'detox',
      },
      {
        label: 'CLA',
        name: 'cla',
      },
      {
        label: 'Hormone Balancer',
        name: 'hormone_balancer',
      },
      {
        label: 'L-Carnitine',
        name: 'l_carnitine',
      },
      {
        label: 'Other',
        name: 'other',
      },
    ],
  },
  anabolics_accelerators: {
    cardTitle: 'Anabolics / Accelerators',
    stackName: '',
    supplementOptions: [
      {
        label: 'Natural Testosterone Booster',
        name: 'natural_testosterone_booster',
      },
      {
        label: 'Women’s Accelerator',
        name: 'womens_accelerator',
      },
      {
        label: 'Estrogen Blocker',
        name: 'estrogen_blocker',
      },
      {
        label: 'Estrogen Balancer',
        name: 'estrogen_balancer',
      },
      {
        label: 'Anabolic Activator',
        name: 'anabolic_activator',
      },
      {
        label: 'Natural Anabolic',
        name: 'natural_anabolic',
      },
      {
        label: 'Post Cycle Support',
        name: 'post_cycle_support',
      },
      {
        label: 'Other',
        name: 'other',
      },
    ],
  },
  workout_foundations: {
    cardTitle: 'Pre- / Intra- Workout Foundations',
    stackName: '',
    supplementOptions: [
      {
        label: 'Preworkout Energizer',
        name: 'preworkout_energizer',
      },
      {
        label: 'EAA/BCAA',
        name: 'eaa_bcaa',
      },
      {
        label: 'Muscle Primer / Enhancer',
        name: 'muscle_primer_enhancer',
      },
      {
        label: 'Nitric Oxide Booster',
        name: 'nitric_oxide_booster',
      },
      {
        label: 'Creatine',
        name: 'creatine',
      },
      {
        label: 'Complex Carbohydrates',
        name: 'complex_carbohydrates',
      },
      {
        label: 'ARA',
        name: 'ara',
      },
      {
        label: 'Other',
        name: 'other',
      },
    ],
  },
};

const StyledCard = styled(Card)`
  margin-top: 80px;
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 20px 0px;
  transition: 0.3s;
  width: 90%;
  overflow: initial;
  background: white;
`;

const StyledCardHeader = styled(CardHeader)`
  width: 88%;
  color: white;
  text-align: center;
  margin: -40px auto 0px;
  border-radius: 1rem;
  background-color: #1c508e;
`;

const SupplementGuide = () => {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate('/meal-plan');
  };

  return (
    <>
      <Typography variant='h3' component='h1' sx={{ fontWeight: '900' }}>
        Supplement Guide
      </Typography>
      <Typography variant='h4' component='h3'>
        {userCtx.name}
      </Typography>
      {Object.entries(supplementItems).map((item: any, index) => {
        const [categoryName, categoryItems] = item;
        return (
          <SupplementsCard
            categoryName={categoryName}
            categoryItems={categoryItems}
            key={categoryName}
          />
        );
      })}

      <StyledCard>
        <StyledCardHeader title={'Notes/Dosing'} />
        <CardContent>
          <FormControl fullWidth sx={{ mb: '1rem' }}>
            <OutlinedInput
              id='notes_dosing'
              placeholder='Type additional notes here'
              minRows={5}
              multiline={true}
              value={userCtx.supplement_guide.notes}
              onChange={(e) =>
                userCtx.addSupplementGuide((prevData: any) => ({
                  ...prevData,
                  notes: e.target.value,
                }))
              }
            />
          </FormControl>
        </CardContent>
      </StyledCard>
      <UIButton onClick={handleClick} style={{ marginTop: '3rem' }}>
        Meal Plan
      </UIButton>
    </>
  );
};

export default SupplementGuide;
