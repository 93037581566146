import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UserContext from 'context/user-context';

import UIButton from 'components/UI/UIButton';

import { Paper, Box, FormControl, TextField, Typography } from '@mui/material';

import FiveStarLogoHoriz from 'assets/5SN-logo-new.png';

const Login = () => {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  const [nameInput, setNameInput] = useState('');
  const [phoneInput, setPhoneInput] = useState('');
  const [nameInputError, setNameInputError] = useState('');
  const [phoneInputError, setPhoneInputError] = useState('');

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!nameInput) {
      setNameInputError('Please enter a name');
    } else {
      setNameInputError('');
    }
    if (!phoneInput) {
      setPhoneInputError('Please enter a valid phone number');
    } else {
      setPhoneInputError('');
    }

    if (nameInput && phoneInput) {
      userCtx.addUserDetails(nameInput, phoneInput);
      navigate('/supplement-guide');
    }
  };

  return (
    <Paper
      sx={{
        padding: '4rem',
        borderRadius: '0.5rem',
        boxShadow: 'rgb(0 0 0 / 12%) 0px 0px 20px 0px',
      }}
      elevation={8}
    >
      <img
        src={FiveStarLogoHoriz}
        alt='Five Star Logo'
        style={{ maxWidth: '12rem', margin: '0 auto 1rem', display: 'block' }}
      />
      <Typography
        variant='h3'
        component='h1'
        textAlign='center'
        sx={{ mb: '1rem', fontWeight: '900' }}
      >
        Health Planner
      </Typography>
      <Box textAlign='center'>
        <form autoComplete='off'>
          <FormControl fullWidth sx={{ mb: '1rem' }}>
            <TextField
              id='user-name'
              placeholder='John Smith'
              label='Name'
              required
              error={!!nameInputError}
              helperText={nameInputError}
              defaultValue={userCtx.name}
              onChange={(e) => setNameInput(e.target.value)}
              variant='outlined'
            ></TextField>
          </FormControl>
          <FormControl fullWidth sx={{ mb: '1rem' }}>
            <TextField
              id='phone-number'
              placeholder='(555) 555-5555'
              label='Phone Number'
              type='tel'
              required
              error={!!phoneInputError}
              helperText={phoneInputError}
              defaultValue={userCtx.phone_number}
              onChange={(e) => setPhoneInput(e.target.value)}
              variant='outlined'
            ></TextField>
          </FormControl>
          <UIButton onClick={handleClick}>Get Started</UIButton>
        </form>
      </Box>
    </Paper>
  );
};

export default Login;
