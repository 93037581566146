import React, {
  createContext,
  FunctionComponent,
  useState,
  useEffect,
} from 'react';

const StateContext = createContext({
  activeStep: 0,
  getActiveStep: (step: number) => {},
});

export const StateContextProvider: FunctionComponent = ({ children }) => {
  const [isActiveStep, setIsActiveStep] = useState(0);

  const activeStepHandler = (step: number) => {
    setIsActiveStep(step);
  };

  useEffect(() => {
    // set active step from local storage
  });

  const value = {
    activeStep: isActiveStep,
    getActiveStep: activeStepHandler,
  };

  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
};

export default StateContext;
