import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import UserContext from 'context/user-context';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import UIButton from 'components/UI/UIButton';
import Disclaimer from 'components/Disclaimer';

const PlanSummary = () => {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate('/thank-you');
  };

  const CodeBlock = styled.pre`
    background-color: #3b4650;
    color: white;
    padding: 1rem;
    width: 100%;
    max-width: 600px;
    word-break: break-all;
    margin-bottom: 0;
  `;

  return (
    <>
      <Typography variant='h3' component='h1' sx={{ fontWeight: '900' }}>
        Plan Summary
      </Typography>
      <Typography variant='h4' component='h3' gutterBottom>
        {userCtx.name}
      </Typography>
      Just a JSON string showing input for now, eventually to be a fully styled
      breakdown.
      <CodeBlock>{JSON.stringify(userCtx, null, 2)}</CodeBlock>
      <Disclaimer />
      <UIButton onClick={handleClick}>Submit</UIButton>
    </>
  );
};

export default PlanSummary;
