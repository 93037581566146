import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { StateContextProvider } from 'context/state-context';
import { UserContextProvider } from 'context/user-context';

import { ThemeProvider } from '@mui/material/styles';
import theme from 'styles/theme';

import CssBaseline from '@mui/material/CssBaseline';

import App from './App';
// import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <StateContextProvider>
      <UserContextProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </UserContextProvider>
    </StateContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
