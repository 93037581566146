import React, { ReactEventHandler, FunctionComponent } from 'react';

import { Button } from '@mui/material';
import styled from '@emotion/styled';
import ArrowForward from '@mui/icons-material/ArrowForward';

interface ButtonProps {
  children?: React.ReactNode;
  style?: any;
  onClick?: ReactEventHandler;
}

const StyledButton = styled(Button)`
  color: white;
`;

const UIButton: FunctionComponent<ButtonProps> = ({
  onClick,
  style,
  children,
}) => {
  return (
    <StyledButton
      variant='contained'
      color='primary'
      size='large'
      style={style}
      disableElevation
      onClick={onClick}
      sx={{ fontSize: 20 }}
      endIcon={<ArrowForward />}
    >
      {children}
    </StyledButton>
  );
};

export default UIButton;
