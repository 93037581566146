import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3D91D3',
    },
    secondary: {
      main: '#1C508E',
    },
    warning: {
      main: '#FE5200',
    },
    background: {
      default: '#eff4f7',
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

export default theme;
