import { useContext } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

// import StateContext from 'context/state-context';
import UserContext from 'context/user-context';

import FiveStarLogoHoriz from 'assets/5SN-logo-new.png';
import {
  Box,
  AppBar,
  Toolbar,
  Container,
  Stepper,
  Step,
  Button,
} from '@mui/material';

const steps = [
  {
    label: 'Login',
    url: '/',
  },
  {
    label: 'Supplement Guide',
    url: '/supplement-guide',
  },
  {
    label: 'Meal Plan',
    url: '/meal-plan',
  },
  {
    label: 'Summary',
    url: '/plan-summary',
  },
];

const Header = () => {
  // const stateCtx = useContext(StateContext);
  const userCtx = useContext(UserContext);

  const navigate = useNavigate();

  const handleExit = () => {
    if (
      window.confirm(
        "This will delete all of the info you've submitted so far. Continue? (use MUI Prompt later)"
      )
    ) {
      userCtx.clearUser();
      navigate('/');
    }
  };

  const StyledNav = styled(NavLink)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 1.25rem 0.1rem;

    &:visited {
      color: #333;
    }
    &.active {
      color: black;
      font-weight: bolder;

      span {
        background-color: #3d91d3;
      }
    }
  `;

  const StyledIcon = styled.span`
    color: white;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: gray;
    border-radius: 100%;
    margin-right: 0.5rem;
  `;

  const Logo = styled.img`
    max-width: 8rem;
    @media (min-width: 1024px) {
      max-width: 12rem;
    }
  `;

  return (
    <AppBar
      position='static'
      sx={{ bgcolor: 'white', boxShadow: 0, padding: '0.5rem 0' }}
      enableColorOnDark
    >
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <Logo src={FiveStarLogoHoriz} alt='5StarNutirtion Logo' />
          </Box>

          <Stepper>
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StyledNav to={step.url}>
                  <StyledIcon>{index + 1}</StyledIcon> {step.label}
                </StyledNav>
              </Step>
            ))}
          </Stepper>
          <Button
            variant='outlined'
            color='warning'
            size='large'
            sx={{ ml: '1rem' }}
            disableElevation
            onClick={handleExit}
          >
            Exit
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
