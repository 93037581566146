import { useContext, useState, FunctionComponent } from 'react';
import { useNonInitialEffect } from 'hooks/useNonInitialEffect';
import UserContext from 'context/user-context';

import styled from '@emotion/styled';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  FilledInput,
  Checkbox,
} from '@mui/material';

interface SupplementsCardProps {
  categoryName: string;
  categoryItems: any;
  // id: number;
  // index: number;
  // deleteItem: any;
  // updateData: any;
  // mealPlanTitle: string;
  // mealPlanItems: Array<string>;
}

const StyledCard = styled(Card)`
  margin-top: 80px;
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 20px 0px;
  transition: 0.3s;
  width: 90%;
  overflow: initial;
  background: white;
`;

const StyledCardHeader = styled(CardHeader)`
  width: 88%;
  color: white;
  text-align: center;
  margin: -40px auto 0px;
  border-radius: 1rem;
  background-color: #1c508e;
`;

const ColumnGroup = styled(FormGroup)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
`;

const SupplementsCard: FunctionComponent<SupplementsCardProps> = ({
  categoryName,
  categoryItems,
}) => {
  const userCtx = useContext(UserContext);
  const [category, setCategory] = useState('postworkout_mealreplacement');
  const [currentStackTitle, setCurrentStackTitle] = useState<string>();
  const [currentSupplements, setCurrentSupplements] = useState<string[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [otherValue, setOtherValue] = useState('');

  const handleCheckboxSelect = (
    e: React.ChangeEvent<HTMLInputElement>,
    categoryName: string
  ) => {
    // logic for other/write-in field
    if (e.target.name === 'other' && e.target.checked) {
      setShowInput(true);
    } else if (e.target.name === 'other' && !e.target.checked) {
      setShowInput(false);
    }

    // pushes selected item into correct category, or removes if it already exists
    setCurrentSupplements((prevData) =>
      prevData.indexOf(e.target.name) > 0
        ? prevData.filter((item) => item !== e.target.name)
        : [...prevData, e.target.name]
    );
    setCategory(categoryName);

    // maybe move context logic to upper-level for consistency's sake(?)
    //updateUserContext(categoryName);

    // userCtx.addSupplementGuide((prevData: any) => ({
    //   ...prevData,
    //   [category]: {
    //     stack: currentStackTitle,
    //     selections: currentSupplements,
    //   },
    // }));
  };

  const updateUserContext = (category: string) => {
    userCtx.addSupplementGuide((prevData: any) => ({
      ...prevData,
      [category]: {
        stack: currentStackTitle,
        selections: currentSupplements,
      },
    }));
  };

  useNonInitialEffect(() => {
    updateUserContext(category);
  }, [currentSupplements, currentStackTitle, category]);

  const handleBlur = (
    e: React.ChangeEvent<HTMLInputElement>,
    category: string
  ) => {
    //setCurrentStackTitle(e.target.value);

    userCtx.addSupplementGuide((prevData: any) => ({
      ...prevData,
      [category]: {
        stack: currentStackTitle,
        selections: currentSupplements,
      },
    }));
  };
  return (
    <StyledCard>
      <StyledCardHeader title={categoryItems.cardTitle} />
      <CardContent>
        <FormControl fullWidth sx={{ mb: '1rem' }}>
          <InputLabel htmlFor='stack-kit'>Stack / Kit</InputLabel>
          <OutlinedInput
            id='stack-kit'
            label='Stack / Kit'
            value={currentStackTitle}
            onChange={(e) => setCurrentStackTitle(e.target.value)}
          />
        </FormControl>
        <Divider />
        <FormControl
          sx={{ m: 3, width: '100%' }}
          component='fieldset'
          variant='standard'
        >
          <ColumnGroup>
            {categoryItems.supplementOptions.map((supplement: any) => {
              return (
                <FormControlLabel
                  control={
                    <>
                      <Checkbox
                        name={supplement.name}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 42 },
                        }}
                        onChange={(e) => handleCheckboxSelect(e, categoryName)}
                      />
                      {supplement.name === 'other' && showInput === true && (
                        <FilledInput
                          sx={{
                            order: 2,
                            marginLeft: '1rem',
                            flexBasis: '75%',
                            background: 'white',
                          }}
                        />
                      )}
                    </>
                  }
                  label={supplement.label}
                  key={supplement.name}
                />
              );
            })}
          </ColumnGroup>
        </FormControl>
      </CardContent>
    </StyledCard>
  );
};

export default SupplementsCard;
