import { useState, FunctionComponent } from 'react';
import { useNonInitialEffect } from 'hooks/useNonInitialEffect';

import styled from '@emotion/styled';
import {
  Card,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Box,
  Chip,
  ListSubheader,
  MenuItem,
  Button,
} from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';

interface MealCardProps {
  id: number;
  index: number;
  deleteItem: any;
  updateData: any;
  mealPlanTitle: string;
  mealPlanItems: Array<string>;
}

const StyledCard = styled(Card)`
  margin-top: 40px;
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 20px 0px;
  transition: 0.3s;
  overflow: initial;
  padding: 1rem;
  background: white;
`;

const StyledSubheader = styled(ListSubheader)`
  background-color: #1c508e;
  color: white;
  font-size: 24px;
`;

const initialFoodItems = {
  proteins: {
    title: 'High Protein',
    serving_size: 'Serving Size: Open palm size to whole open hand size',
    options: [
      'Poultry',
      'Fish',
      'Shellfish',
      'Pork',
      'Beef',
      'Bison/Venison',
      'Eggs',
      'Alternative Meat',
      'Tofu',
      'Jerky',
      'Protein Bar/Snack',
      'Protein Shake',
      'Cottage Cheese',
      'Plain Yogurt',
    ],
  },
  veggies: {
    title: 'Best Veggies',
    serving_size: 'Serving size: Unlimited',
    options: [
      'Spinach',
      'Kale',
      'Arugula',
      'Broccoli',
      'Zucchini',
      'Asparagus',
      'Bell Peppers',
      'Cauliflower',
      'Celery',
      'Green Beans',
      'Brussel Sprouts',
      'Mushrooms',
      'Cucumber',
      'Romaine Lettuce',
    ],
  },
  fats: {
    title: 'Healthy Fats',
    serving_size:
      'Serving size: Oils/Butters: 1/2 - 1 thumb size. Solid Foods: golf ball to lightbulb size',
    options: [
      'Nuts',
      'Seeds',
      'Avocado',
      'Coconut',
      'Olives',
      'Ghee',
      'Nut/Seed Butters',
      'Avocado Oil',
      'Coconut Oil',
      'Olive Oil',
    ],
  },
  carbs: {
    title: 'Ideal Carbs',
    serving_size: 'Serving size: Between 1/2 -1 tennis ball size',
    options: [
      'Rice',
      'Potatoes (ALL)',
      'Legumes',
      'Carrots',
      'Squash',
      'Oats',
      'Ezekiel products',
      'Quinoa',
      'Buckwheat',
      'Fruit',
    ],
  },
  snacks: {
    title: 'Healthy Snacks',
    serving_size: '',
    options: [
      '1/2 cup plain yogurt w/ 1 scoop protein',
      '1⁄4 cup walnuts w/ protein shake',
      '1 cup jicama, dip into 1⁄4 cup guacamole',
      'Protein shake w/ 1 cup strawberries or plum',
      '1 cup cottage cheese w/ 1cup mixed berries',
      '1-2 oz beef jerky w/ 1⁄4 cup seeds/nuts',
      'Anything from Snack Station',
    ],
  },
};

const MealPlanCard: FunctionComponent<MealCardProps> = ({
  id,
  mealPlanTitle,
  mealPlanItems,
  deleteItem,
  updateData,
  index,
}) => {
  const [currentPlanTitle, setCurrentPlanTitle] =
    useState<string>(mealPlanTitle);
  const [currentPlanItems, setCurrentPlanItems] =
    useState<string[]>(mealPlanItems);

  useNonInitialEffect(() => {
    setCurrentPlanTitle(mealPlanTitle);
    setCurrentPlanItems(mealPlanItems);
  }, [mealPlanTitle, mealPlanItems]);

  useNonInitialEffect(() => {
    updateData({
      id: id,
      mealPlanTitle: currentPlanTitle,
      mealPlanItems: currentPlanItems,
    });
  }, [currentPlanTitle, currentPlanItems]);

  const handleMealChange = (e: SelectChangeEvent<typeof currentPlanItems>) => {
    const {
      target: { value },
    } = e;
    setCurrentPlanItems(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDeleteChip = (e: React.SyntheticEvent, value: string) => {
    e.preventDefault();
    // setCurrentPlanItems((prevData) => {
    //   console.log(prevData);
    //   console.log(prevData.indexOf(value));
    //   return prevData;
    // });
    setCurrentPlanItems((prevData) =>
      prevData.indexOf(value) >= 0
        ? prevData.filter((item) => item !== value)
        : [...prevData, value]
    );

    console.log(currentPlanItems);
  };

  return (
    <StyledCard key={id}>
      <FormControl fullWidth sx={{ mb: '1rem' }}>
        <InputLabel htmlFor='meal-name'>Meal Name</InputLabel>
        <OutlinedInput
          id='meal-name'
          placeholder='Breakfast'
          label='Meal Name'
          value={currentPlanTitle}
          onChange={(e) => setCurrentPlanTitle(e.target.value)}
          onBlur={() =>
            updateData({
              id: id,
              mealPlanTitle: currentPlanTitle,
              mealPlanItems: currentPlanItems,
            })
          }
        />
      </FormControl>

      <FormControl fullWidth>
        <InputLabel id='demo-multiple-chip-label'>Food Items</InputLabel>
        <Select
          labelId='demo-multiple-chip-label'
          id='demo-multiple-chip'
          multiple
          value={currentPlanItems}
          onChange={handleMealChange}
          onBlur={() =>
            updateData({
              id: id,
              mealPlanTitle: currentPlanTitle,
              mealPlanItems: currentPlanItems,
            })
          }
          MenuProps={{ PaperProps: { sx: { maxHeight: 400 } } }}
          input={<OutlinedInput id='select-multiple-chip' label='Food Items' />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  sx={{ fontSize: '18px' }}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  onDelete={(e) => handleDeleteChip(e, value)}
                />
              ))}
            </Box>
          )}
        >
          {Object.entries(initialFoodItems).map((item: any) => {
            const foodCategory = item[1];
            const menuItems = foodCategory.options.map((foodItem: string) => (
              <MenuItem key={foodItem} value={foodItem}>
                {foodItem}
              </MenuItem>
            ));
            return [
              <StyledSubheader key={foodCategory.title}>
                {foodCategory.title}{' '}
                {foodCategory.serving_size
                  ? `- ${foodCategory.serving_size}`
                  : null}
              </StyledSubheader>,
              menuItems,
            ];
          })}
        </Select>
      </FormControl>
      {index > 0 ? (
        <Button
          onClick={() => deleteItem(id)}
          size='large'
          color='warning'
          sx={{ marginTop: '1rem' }}
        >
          Delete Meal
          <AddCircle sx={{ marginLeft: '0.5rem' }} />
        </Button>
      ) : null}
    </StyledCard>
  );
};

export default MealPlanCard;
