import { useNavigate } from 'react-router-dom';

import { Typography, Button } from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';

import SuccessAnim from 'components/UI/SuccessAnim';

const ThankYou = () => {
  const navigate = useNavigate();

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <>
      <SuccessAnim />
      <Typography variant='h2' component='h1' sx={{ fontWeight: '900' }}>
        Thank You!
      </Typography>
      <Typography sx={{ mb: '1rem' }}>
        Your information has been submitted. Check your phone for a link to view
        your plan at any time.
      </Typography>
      <Button>Download Plan (PDF)</Button>
      <Button
        variant='contained'
        color='primary'
        size='large'
        sx={{ mt: '1rem', fontSize: 20 }}
        disableElevation
        onClick={handleClick}
        endIcon={<ArrowForward />}
      >
        Return Home
      </Button>
    </>
  );
};

export default ThankYou;
