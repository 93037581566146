import { Typography } from '@mui/material';

const Disclaimer = () => {
  return (
    <Typography variant='caption' maxWidth={600} sx={{ m: '3rem' }}>
      *This information is not intended as a substitute for individual medical
      advice in diagnosing or treating a health problem. Please consult your
      doctor, health care provider, or other health care professional before
      beginning a new diet, exercise, or supplement regimen.
    </Typography>
  );
};

export default Disclaimer;
