import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UserContext from 'context/user-context';

import styled from '@emotion/styled';
import {
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  OutlinedInput,
  Button,
} from '@mui/material';
import AddCircle from '@mui/icons-material/AddCircle';

import UIButton from 'components/UI/UIButton';
import MealPlanCard from 'components/MealPlanCard';

const StyledCard = styled(Card)`
  margin-top: 40px;
  border-radius: 0.5rem;
  box-shadow: rgb(0 0 0 / 12%) 0px 0px 20px 0px;
  transition: 0.3s;
  overflow: initial;
  padding: 1rem;
  background: white;
`;

const StyledCardHeader = styled(CardHeader)`
  width: 88%;
  color: white;
  text-align: center;
  margin: -40px auto 0px;
  border-radius: 1rem;
  background-color: #1c508e;
`;

const defaultData = [
  {
    id: Math.round(Date.now() + Math.random()),
    mealPlanTitle: '',
    mealPlanItems: [],
  },
];

const MealPlan = () => {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  const [mealPlanData, setMealPlanData] = useState(
    userCtx.meal_plan.meal_plan_items.length > 0
      ? userCtx.meal_plan.meal_plan_items
      : defaultData
  );

  const handleAddItem = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setMealPlanData((prevData) => [
      ...prevData,
      ...[
        {
          id: Math.round(Date.now() + Math.random()),
          mealPlanTitle: '',
          mealPlanItems: [],
        },
      ],
    ]);
  };

  const handleDeleteItem = (id: number) => {
    setMealPlanData((prevData) =>
      prevData.filter((mealItem) => mealItem.id !== id)
    );
  };

  const handleUpdateData = (data: any) => {
    if (data) {
      setMealPlanData((prevData) =>
        prevData.map((mealItem) =>
          mealItem.id === data.id ? { ...data } : mealItem
        )
      );
    }
  };

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();

    // add meal plans to user context
    userCtx.addMealPlanDetails((prevData: any) => ({
      ...prevData,
      meal_plan_items: mealPlanData,
    }));

    navigate('/plan-summary');
  };

  return (
    <>
      <Typography variant='h3' component='h1' sx={{ fontWeight: '900' }}>
        Healthy Eating Meal Plan
      </Typography>
      <Typography variant='h4' component='h3'>
        {userCtx.name}
      </Typography>
      <Grid
        container
        spacing={4}
        sx={{ mt: '0.75rem', maxWidth: '90%' }}
        alignItems='center'
        justifyContent='center'
      >
        <Grid item xs={3}>
          <Typography variant='h5' component='h4' sx={{ fontWeight: 'bolder' }}>
            Daily Nutrient Goals:
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor='daily-calories'>Daily Calories</InputLabel>
            <OutlinedInput
              id='daily-calories'
              placeholder='2000'
              type='number'
              value={userCtx.meal_plan.daily_calories}
              endAdornment='kcal'
              onChange={(e) =>
                userCtx.addMealPlanDetails((prevData: any) => ({
                  ...prevData,
                  daily_calories: e.target.value,
                }))
              }
              sx={{ background: 'white' }}
              label='Daily Calories'
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor='daily-proteins'>Daily Proteins</InputLabel>
            <OutlinedInput
              id='daily-proteins'
              placeholder='100'
              type='number'
              value={userCtx.meal_plan.daily_proteins}
              endAdornment='grams'
              onChange={(e) =>
                userCtx.addMealPlanDetails((prevData: any) => ({
                  ...prevData,
                  daily_proteins: e.target.value,
                }))
              }
              sx={{ background: 'white' }}
              label='Daily Proteins'
            />
          </FormControl>
        </Grid>
      </Grid>
      <Divider sx={{ m: '2rem auto 0', width: '80%' }} />
      <Grid container spacing={2} textAlign='center'>
        <Grid item xs={12} sx={{ mb: '1rem' }}>
          {mealPlanData.map(({ id, mealPlanTitle, mealPlanItems }, index) => (
            <MealPlanCard
              id={id}
              index={index}
              deleteItem={handleDeleteItem}
              updateData={handleUpdateData}
              mealPlanTitle={mealPlanTitle}
              mealPlanItems={mealPlanItems}
              key={id}
            />
          ))}
        </Grid>
        <Grid item xs={12}>
          <Button onClick={handleAddItem} size='large' sx={{ fontSize: 20 }}>
            Add New Meal
            <AddCircle sx={{ fontSize: '2rem', marginLeft: '0.5rem' }} />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <StyledCard>
            <StyledCardHeader title={'Notes'} />
            <CardContent>
              <FormControl fullWidth sx={{ mb: '1rem' }}>
                <OutlinedInput
                  id='notes_dosing'
                  placeholder='Type additional notes here'
                  minRows={5}
                  multiline={true}
                  value={userCtx.meal_plan.notes}
                  onChange={(e) =>
                    userCtx.addMealPlanDetails((prevData: any) => ({
                      ...prevData,
                      notes: e.target.value,
                    }))
                  }
                />
              </FormControl>
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>

      <UIButton onClick={handleClick} style={{ marginTop: '3rem' }}>
        Review Plan
      </UIButton>
    </>
  );
};

export default MealPlan;
