import React, { createContext, FunctionComponent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface UserContextInterface {
  id?: string;
  name: string;
  phone_number: string;
  supplement_guide?: any;
  meal_plan?: {
    daily_calories: any;
    daily_proteins: number;
    meal_plan_items: Array<any>;
    notes: string;
  };
  addUserDetails: any;
  addMealPlanDetails: any;
  clearUser: any;
}

const UserContext = createContext({
  id: '',
  name: 'John Smith',
  phone_number: '',
  supplement_guide: {
    notes: '',
  },
  meal_plan: {
    daily_calories: '',
    daily_proteins: '',
    meal_plan_items: [],
    notes: '',
  },
  addUserDetails: (name: string, phone_number: string) => {},
  addSupplementGuide: (supplement_guide: object) => {},
  addMealPlanDetails: (meal_plan: object) => {},
  clearUser: () => {},
});

// const supplement_guide = {
//   health_wellness: {
//     stack: '',
//     selections: []
//   },
//   notes: '',
// };

const meal_plan = {
  daily_calories: '',
  daily_proteins: '',
  meal_plan_items: [],
  notes: '',
};

export const UserContextProvider: FunctionComponent = ({ children }) => {
  const [userId, setUserId] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userSupplementGuide, setUserSupplementGuide] = useState({ notes: '' });
  const [userMealPlan, setUserMealPlan] = useState(meal_plan);

  const addUserDetailsHandler = (name: string, phone_number: string) => {
    // maybe make it so that it only generates a new ID if one isn't set?
    const id = uuidv4();
    setUserId(id);
    setUserName(name);
    setUserPhone(phone_number);

    //add to localstorage in case it refreshes
  };

  const addSuppementGuideHandler = (supplement_guide: any) => {
    setUserSupplementGuide(supplement_guide);

    //add to localstorage in case it refreshes
  };

  const addMealPlanDetailsHandler = (meal_plan: any) => {
    setUserMealPlan(meal_plan);

    //add to localstorage in case it refreshes
  };

  const clearUserHandler = () => {
    setUserId('');
    setUserName('');
    setUserPhone('');
    setUserMealPlan(meal_plan);
    setUserSupplementGuide({ notes: '' });

    //remove from localstorage
  };

  const value = {
    id: userId,
    name: userName,
    phone_number: userPhone,
    supplement_guide: userSupplementGuide,
    meal_plan: userMealPlan,
    addUserDetails: addUserDetailsHandler,
    addSupplementGuide: addSuppementGuideHandler,
    addMealPlanDetails: addMealPlanDetailsHandler,
    clearUser: clearUserHandler,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserContext;
